<template>
  <div class="container">
    <a-layout   id="components-layout-demo-top-side">
      <a-layout-header class="header">
        <loginheader :activekey="4"></loginheader>
      </a-layout-header>
    </a-layout>
    <a-layout-content>
    <div class="produce_content">
    <div class="videocontent">
      <video-player  style="width: 100%;height: 100%;"  class="video-player vjs-custom-skin"
                     ref="videoPlayer"
                     :playsinline="true"
                     :options="playerOptions"
      ></video-player>
    </div>
      <div class="p_content">
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中医药文化凝聚着深邃的哲学智慧和中华民族几千年的
        健康养生理念集其实践经验，是中国古代科学的瑰宝也是打开中华
        文明宝库的钥匙。“继承和弘扬中医文化对于推动”健康中国“建设、文
        化强国战略都发挥这不可代替的作用。
      </p>
        <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中医药文化进校园不仅丰富学生的课余生活，提高了学生学
        习中医药文化的兴趣，弘扬了中医药文化，增强学生的文化自信，还
        能起到强身健体，提高疾病预防能力的作用。
        </p>
        <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;传承和发扬中国传统文化我们的职责和使命，希望能在孩子
        们的心中植下中医药传统文化的种子让它生根发芽，茁壮成长，
        </p>
      </p>
      </div>
    </div>
    </a-layout-content>

    <a-layout-footer style="padding: 0">，
      <loginfooter></loginfooter>
    </a-layout-footer>
  </div>

</template>

<script>
import loginheader from "../home/components/loginheader";
import loginfooter from "../home/components/loginfooter";
export default {
  name: "videoproduce",
  components:{
    loginheader:loginheader,
    loginfooter:loginfooter
  },
  data(){
    return {

        // 视频播放
        playerOptions : {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "" //url地址
          }],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth, //播放器宽度
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        }


    }
  },
  methods:{

  }
}
</script>
<style>
@media (min-width: 1024px){
  body,html{font-size: 18px!important;}
}
@media (min-width: 1100px) {
  body,html{font-size: 20px!important;}
}
@media (min-width: 1280px) {
  body,html{font-size: 22px!important;}
}
@media (min-width: 1366px) {
  body,html{font-size: 24px!important;}
}
@media (min-width: 1440px) {
  body,html{font-size: 25px!important;}
}
@media (min-width: 1680px) {
  body,html{font-size: 28px!important;}
}
@media (min-width: 1920px) {
  body,html{font-size: 33px!important;}
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header{
  height: 2.76rem;
  background: linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);

  box-sizing: border-box;

}
.produce_content{
display: flex;
  flex-direction: row;
  height: 11rem;
  position: relative;
  width: 26rem;
  margin: 4.6rem auto 6rem;
  .videocontent{

    width: 13rem;
  }
  .p_content{
    margin-left: 1rem;
    width: 13rem;
    p{
      font-size: 0.6rem;
      font-family: jiangxizhuokai;
    line-height: 1rem;
      color: #131313;
    }
  }
}
.bottom{
  padding: 0;
  z-index: 999;
  position: absolute;
  width: 10rem;

  bottom:0;
  right: 0;
  width:11.8rem;
  img{
    width: 100%;
  }

}
</style>